<template>
  <tr>
    <td>
      {{ membertype.id }}
    </td>
    <td>
      {{ membertype.name }}
    </td>
    <td class="text-right pr-0" >
     <a href="#" class="btn btn-icon btn-light btn-sm" @click="deleteMemberTypeClicked($event, membertype)">
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
        </span>
      </a>
      <a href="#" class="btn btn-icon btn-light btn-sm mx-3" @click="selectMemberTypeClicked(membertype)">
        <span class="svg-icon svg-icon-md svg-icon-primary">
          <inline-svg src="/assets/svg/Write.svg"></inline-svg>
        </span>
      </a>
    </td>
  </tr>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex';

export default {
  name: 'membertype-table-row',
  props: ['membertype'],
  emits: ['deleteMemberTypeClicked', 'selectMemberTypeClicked'],
  computed: {
    ...mapGetters(['currentCompanyId'])
  },
  data() {
    return {};
  },
  mounted() {},
  watch: {},
  methods: {
    deleteMemberTypeClicked(e, membertype) {
      e.preventDefault();

      this.$emit('deleteMemberTypeClicked', membertype);
    },
    selectMemberTypeClicked(membertype) {
      this.$emit('selectMemberTypeClicked', membertype);
    }
  }
};
</script>
